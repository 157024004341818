<template>
    <div @click.stop>
        <v-btn
            @click.stop="showPanel = !showPanel"
            :block="block"
            :class="buttonClass"
            :height="inline ? 'null' : height"
            :icon="hasIcon && label == null"
            :large="large"
            :small="small"
            :title="title">
            <v-icon v-if="hasIcon && label == null" :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
            <v-row v-else-if="!inline" class="mt-0 mb-3">
                <v-col v-if="leftIcon != null" class="mx-0 mx-auto px-auto" cols="12">
                    <v-icon :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
                </v-col>
                <v-col v-if="label != null" class="ma-0 pa-0" cols="12">
                    <div>{{ label }}</div>
                </v-col>
            </v-row>
            <span v-else>
                <v-icon v-if="leftIcon != null" :large="large" :small="small" :left="label != null">{{ leftIcon }}</v-icon>
                {{ label }}
                <v-icon v-if="rightIcon != null" :large="large" :small="small" :right="label != null">{{ rightIcon }}</v-icon>
            </span>
        </v-btn>

        <v-navigation-drawer
            v-model="showPanel"
            app
            :bottom="bottom"
            :clipped="clipped"
            :hide-overlay="hideOverlay"
            right
            style="height: 100%;"
            :width="navWidth">
            <v-toolbar dense>
                <v-btn
                    icon
                    small
                    title="Close"
                    @click.stop="showPanel = false">
                    <v-icon>{{ ($vuetify.breakpoint.mobile && bottom) ? 'mdi-arrow-down' : 'mdi-arrow-right' }}</v-icon>
                </v-btn>
                <v-toolbar-title>{{ sidebarLabel }}</v-toolbar-title>
                <v-spacer />
                <v-btn
                    v-if="canRefresh"
                    icon
                    small
                    title="Refresh"
                    @click.stop="refresh">
                    <v-icon small>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <v-alert v-model="errorMsg" dismissible type="error">{{ errorMsg }}</v-alert>

            <v-list
                v-if="asyncItems != null"
                height="auto"
                class="overflow-y-auto">
                <!-- <v-list-item-subtitle v-if="subheader != null">{{ subheader }}</v-list-item-subtitle> -->
                <template v-for="(item, index) in asyncItems">
                    <v-card :key="index" class="my-2">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Link</v-list-item-title>
                                <v-list-item-subtitle>Created {{ item.createdOn | toRelativeTime }}</v-list-item-subtitle>
                                <!-- <v-list-item-subtitle>{{ item.url }}</v-list-item-subtitle> -->
                                <v-list-item-subtitle>Expires {{ item.expiresOn | toRelativeTime }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <BT-Btn icon="mdi-content-copy" @click="copyToClipboard(item.url)" title="Copy URL" />
                            </v-list-item-action>
                        </v-list-item>
                        <v-card-actions>
                            <v-btn small text @click.stop="emailLink(item)" :class="item.isEmailed ? null : 'primary'" :disabled="item.isEmailed">
                                {{ item.isEmailed ? 'Link Sent' : 'Email Link' }}
                            </v-btn>
                            <v-spacer />
                            <v-btn small text @click.stop="deleteLink(item)" class="error">
                                Remove
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                    <v-divider v-if="dividers" :key="'d' + index" />
                </template>
                <slot name="bottom">
                    <v-row dense class="ma-0">
                        <v-btn block @click.stop="createLink(false)" class="primary my-2">
                            Create New Link
                        </v-btn>
                    </v-row>
                </slot>
            </v-list>

            <v-overlay :value="loadingMsg != null" absolute key="6" class="text-center">
                <v-progress-circular indeterminate size="32" />
                <p>{{ loadingMsg }}</p>
            </v-overlay>
        </v-navigation-drawer>
    </div>
</template>

<script>
import urlHelpers from '~tools/url-helpers.js';

export default {
    name: 'BT-Sidebar-Live-Links',
    data: function() {
        return {
            asyncItems: [],
            errorMsg: null,
            loadingMsg: null,
            showPanel: false,
        }
    },
    props: {
        block: {
            type: Boolean,
            default: false
        },
        bottom: {
            type: Boolean,
            default: true
        },
        buttonClass: {
            type: String,
            default: 'ma-1'
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        clipped: {
            type: Boolean,
            default: true
        },
        dividers: {
            type: Boolean,
            default: true
        },
        hideOverlay: {
            type: Boolean,
            default: true
        },
        inline: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        leftIcon: {
            type: String,
            default: null
        },
        navWidth: {
            type: String,
            default: '350'
        },
        rightIcon: {
            type: String,
            default: null
        },
        sidebarLabel: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        }
    },
    watch: {
        showPanel: function(val) {
            if (val) {
                this.pullItems();
            }
        }
    },
    computed: {
        canAuthEdit() {
            return this.$canEdit('sign-over-lnks');
        },
        hasIcon() {
            return this.leftIcon != null || this.rightIcon != null;
        },
        height() {
            return this.small ? 30 : (this.large ? 80 : 'auto');
        }
    },
    methods: {
        async createLink(sendEmail = false) { //many item
            try {
                this.loadingMsg = 'Creating Link';
                var data = { childCompanyID: this.itemID, sendEmail: sendEmail };
                var res = await this.$BlitzIt.store.post('sign-over-links', data);
                
                this.asyncItems.push(Object.assign({}, res, { errorMsg: null, url: urlHelpers.formLinkURL(res.id), isEmailed: false }));
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async deleteLink(item) {
            try {
                this.loadingMsg = 'Removing Link';

                await this.$BlitzIt.store.delete('sign-over-links', item.id);
                
                var ind = this.asyncItems.findIndex(x => x.id == item.id);
                if (ind > -1) {
                    this.asyncItems.splice(ind, 1);
                }
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async emailLink(item) {
            try {
                this.loadingMsg = 'Sending Email';
                await this.$BlitzIt.api.post('sign-over-links', null, null, '/post/SendEmail/' + item.id);
                item.isEmailed = true;
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async pullItems(refresh = false) {
            try {
                this.loadingMsg = 'Searching For Links';

                var res = await this.$BlitzIt.store.getAll('sign-over-links', { includeDetails: false, childID: this.itemID }, refresh, null, null);
                
                this.asyncItems = res.map(x => Object.assign({}, x, { errorMsg: null, url: urlHelpers.formLinkURL(x.id), isEmailed: false }));
                
                this.$emit('fetched', this.asyncItems);
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        refresh(refresh = true) {
            this.errorMsg = null;
            this.pullItems(refresh);
        },
    }
}
</script>